import { BaseService } from './baseService'
import apis from './apis'

export class PartnerService extends BaseService {
  static async getPartners(pars) {
    try {
      const response = await this.request({ auth: true }).get(apis.get_partners)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getSales(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.get_sales,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async createAccount(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.create_account,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async updateSaleNinjavan(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.update_sale_ninjavan,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async activeSaleNinjavan(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.active_sale_ninjavan,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async deactiveSaleNinjavan(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.deactive_sale_ninjavan,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async deleteSaleNinjavan(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.delete_sale_ninjavan,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getServiceAll() {
    try {
      const response = await this.request({ auth: true }).get(
        apis.services_all
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
}
